import { Component } from 'react';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { moveToTotpScan } from 'TwoFA/helpers/common';
import getDownloadInfo from '../helpers/api';
import withCheckVisibility from '../../../hocs/with_check_visibility';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brand: null,
      name: null,
      url: null,
    };
  }

  componentDidMount() {
    getDownloadInfo().then((response) => {
      this.setState({
        brand: response.brand,
        name: response.name,
        url: response.url,
      });
    });
  }

  download = () => {
    const { url } = this.state;
    window.location.href = url;
  };

  render() {
    const { brand, name, url } = this.state;

    return (
      <div className="bvs-card twofa__container twofa-totp-intro">
        <div className="twofa-totp-intro-header">
          <div className="twofa-totp-intro__icon" />
          <p className="twofa-totp-intro__intro-text">
            {t('javascript.twofa.settings.totp.intro.header')}
          </p>
        </div>
        <hr />
        {brand
          && (
            <div className="twofa-totp-intro-info">
              <span className="bvs-icon is-info twofa-totp-intro-info__icon" />
              <span className="twofa-totp-intro-info__text">
                <p>{t('javascript.twofa.settings.totp.intro.info.recommendation', { brand, app: name })}</p>
                <p>{t('javascript.twofa.settings.totp.intro.info.instructions')}</p>
              </span>
            </div>
          )}
        {url
          && (
            <Button
              className="twofa-totp-intro__button-download"
              secondary
              inverse
              label={t('javascript.twofa.settings.download_button', { app: name })}
              onClick={this.download}
            />
          )}
        <Button
          className="twofa-totp-intro__button-continue"
          primary
          label={t('javascript.twofa.settings.continue_button')}
          onClick={moveToTotpScan}
        />
      </div>
    );
  }
}

export default withCheckVisibility('totp')(App);
